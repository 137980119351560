import { Launch } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid2,
  Stack,
  Typography,
} from "@mui/material";
import { Loader } from "Components";
import { CloseButton } from "Components/closeButton/closeButton";
import { EllipsisText } from "Components/ellipsisText";
import { ROUTES } from "Constants";
import { useFullscreenContext } from "Contexts/fullScreenContext";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { JobsList, MapCard } from "Screens/order-details/components";
import { AppState } from "Store";
import { sagaActions as orderDetailsSagaActions } from "Store/orderDetails/orderDetailsSagaActions";
import { sagaActions as planningV2SagaActions } from "Store/planningV2/planningV2SagaActions";
import { displayDateRestriction } from "Utils/date";

type OrderDetailsModalProps = {
  orderId: number;
  open: boolean;
  onClose: () => void;
};

export const OrderDetailsModal: React.FC<OrderDetailsModalProps> = ({
  orderId,
  open,
  onClose,
}) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { fullscreenContainer } = useFullscreenContext();

  const { order, orderLoading } = useSelector(
    (state: AppState) => state.orderDetails,
  );

  useEffect(() => {
    if (orderId && open) {
      dispatch(orderDetailsSagaActions.getOrder(orderId));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, open]);

  const close = useCallback(() => {
    onClose();
    dispatch(planningV2SagaActions.fetchOrders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  const navigateToOrderDetails = useCallback(() => {
    navigate(`${ROUTES.MAIN.ORDERS}/${orderId}`);
  }, [navigate, orderId]);

  const dateRestrictions = useMemo(
    () =>
      displayDateRestriction(
        order?.dateRestrictions,
        t("orders.createModal.toDo"),
        t("orders.createModal.until"),
      ),
    [order?.dateRestrictions, t],
  );

  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      maxWidth="lg"
      scroll="paper"
      container={fullscreenContainer}
      PaperProps={{
        sx: {
          minHeight: "80vh",
        },
      }}
    >
      {orderLoading ? (
        <Box
          sx={{
            position: "absolute",
            left: 0,
            zIndex: 1000000,
            background: "#fff",
            width: "100%",
            height: "100%",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <>
          <DialogTitle sx={{ pb: 1 }}>
            <Stack direction={"row"}>
              <Stack>
                {order?.name} - {order?.customer.companyName}
                <Typography
                  component={"span"}
                  variant="subtitle2"
                  color="text.secondary"
                >
                  {dateRestrictions}
                </Typography>
              </Stack>
              <Button
                variant="text"
                endIcon={<Launch />}
                sx={{
                  position: "absolute",
                  right: 60,
                  top: 10,
                }}
                onClick={navigateToOrderDetails}
              >
                {t("orders.open")}
              </Button>
            </Stack>
          </DialogTitle>
          <CloseButton onClose={close} />
          <DialogContent dividers sx={{ position: "relative" }}>
            <Grid2 container spacing={2} sx={{ height: "calc(80vh - 100px)" }}>
              <Grid2
                sx={{ height: "100%" }}
                display={"flex"}
                flexDirection={"column"}
                size={{
                  xs: 12,
                  sm: 5,
                  md: 4,
                  lg: 3,
                }}
              >
                <Grid2
                  sx={{
                    flexGrow: 8,
                    flexBasis: "80%",
                    overflow: "hidden",
                    padding: 0.1,
                  }}
                >
                  <Card sx={{ height: "100%" }} elevation={3}>
                    <JobsList slim />
                  </Card>
                </Grid2>
                <Grid2
                  sx={{
                    marginTop: 1,
                    flexGrow: 2,
                    flexBasis: "20%",
                    padding: 0.1,
                  }}
                >
                  <Card sx={{ height: "100%" }} elevation={3}>
                    <CardContent>
                      <Typography variant="subtitle1">
                        {t("general.fields.notes")}
                      </Typography>
                      <EllipsisText
                        text={order?.notes || "-"}
                        variant="body2"
                        color="text.secondary"
                        maxLines={3}
                      ></EllipsisText>
                    </CardContent>
                  </Card>
                </Grid2>
              </Grid2>
              <Grid2
                size={{
                  xs: 12,
                  sm: 7,
                  md: 8,
                  lg: 9,
                }}
                sx={{ height: "100%" }}
              >
                <MapCard disableJobDetails />
              </Grid2>
            </Grid2>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};
