import { closestCorners, DndContext, DragOverlay } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { Box, List, Typography } from "@mui/material";
import { ConfirmDialog, Loader } from "Components";
import { type AppState } from "Store";
import { sagaActions } from "Store/orderDetails/orderDetailsSagaActions";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { JobDetailsModal } from "../jobDetailsModal";
import { JobOverlay } from "./jobOverlay";
import { PartialOrder } from "./partialOrder";
import { UnplanContext } from "./unplanContext";
import { useDragging } from "./useDragging";

export enum SORTABLE_CONTEXT {
  EVENT = "event",
  PLANNED = "planned",
  UNPLANNED = "unplanned",
}

type JobListProps = {
  slim?: boolean;
};

export const JobsList: React.FC<JobListProps> = ({ slim = false }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [t] = useTranslation();

  const jobWrapperRef = useRef<HTMLDivElement>(null);

  const [selectedJobId, setSelectedJobId] = useState<number>();
  const [jobIdForDeletion, setJobIdForDeletion] = useState<number>();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [isJobDetailsModalOpen, setIsJobDetailsModalOpen] =
    useState<boolean>(false);

  const { order, orderLoading, events, plannedJobs, unplannedJobs } =
    useSelector((state: AppState) => state.orderDetails);
  const jobs = useSelector(
    (state: AppState) => state.orderDetails.order?.jobDetails?.jobs,
  );

  const { currentlyDraggedJob, onDragStart, onDragEnd, sensors } = useDragging(
    order,
    events,
    plannedJobs,
    unplannedJobs,
  );

  const jobsAvailable = useMemo(() => (jobs?.length ?? 0) > 0, [jobs?.length]);

  const showJobDetails = useCallback(
    (id: number) => {
      setSelectedJobId(id);
      dispatch(sagaActions.getJobTelemtry(id));
      setIsJobDetailsModalOpen(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onClick = useCallback(
    (_: React.MouseEvent<HTMLDivElement, MouseEvent>, id: number) => {
      if (!slim)
        navigate(location.pathname, { replace: true, state: { jobId: id } });
    },
    [slim, navigate, location.pathname],
  );

  const onDelete = useCallback((jobId: number) => {
    setJobIdForDeletion(jobId);
    setIsConfirmModalOpen(true);
  }, []);

  const handleDeleteApprove = useCallback(() => {
    dispatch(sagaActions.deleteJob(jobIdForDeletion!));
    setIsConfirmModalOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobIdForDeletion]);

  useEffect(() => {
    const jobId = location.state?.jobId;

    if (jobId !== undefined) {
      showJobDetails(jobId);
    }
  }, [location.state?.jobId, showJobDetails]);

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {!slim && (
        <Box
          padding={1}
          textAlign="center"
          sx={(theme) => ({ backgroundColor: theme.palette.grey[700] })}
        >
          <Typography
            variant="button"
            sx={(theme) => ({
              color: theme.palette.getContrastText(theme.palette.grey[700]),
            })}
          >
            {t("general.navigation.jobs")} {jobs?.length && `(${jobs.length})`}
          </Typography>
        </Box>
      )}
      <Box
        ref={jobWrapperRef}
        sx={{
          flex: 1,
          overflowY: "scroll",
          pb: slim ? undefined : 10,
        }}
      >
        {!orderLoading && jobsAvailable && (
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            collisionDetection={closestCorners}
            sensors={sensors}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
          >
            <List>
              {events.map((event, i) => (
                <PartialOrder
                  key={event._id}
                  title={t("orders.details.overview.partialOrder", {
                    index: i + 1,
                  })}
                  jobs={event.jobs}
                  event={event}
                  onJobClick={onClick}
                  onJobDelete={onDelete}
                  contextId={SORTABLE_CONTEXT.EVENT + event._id.toString()}
                />
              ))}
              {plannedJobs.length > 0 && (
                <PartialOrder
                  title={`${t("general.labels.planned")}`}
                  jobs={plannedJobs}
                  allowDragging={false}
                  onJobClick={onClick}
                  onJobDelete={onDelete}
                  contextId={SORTABLE_CONTEXT.PLANNED}
                />
              )}
              {unplannedJobs?.length > 0 && (
                <PartialOrder
                  title={`${t("general.labels.unplanned")}`}
                  jobs={unplannedJobs}
                  allowDragging={unplannedJobs.length > 1}
                  onJobClick={onClick}
                  onJobDelete={onDelete}
                  contextId={SORTABLE_CONTEXT.UNPLANNED}
                />
              )}
              <UnplanContext />
            </List>
            <DragOverlay>
              {currentlyDraggedJob ? (
                <JobOverlay job={currentlyDraggedJob} />
              ) : null}
            </DragOverlay>
          </DndContext>
        )}
        {!jobsAvailable && (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
            }}
          >
            <Typography>{t("orders.details.jobDetails.noJobs")}</Typography>
          </Box>
        )}
        {orderLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        )}
      </Box>
      <ConfirmDialog
        isOpen={isConfirmModalOpen}
        onApprove={handleDeleteApprove}
        onClose={() => setIsConfirmModalOpen(false)}
      />
      <JobDetailsModal
        isOpen={isJobDetailsModalOpen}
        onClose={() => setIsJobDetailsModalOpen(false)}
        jobId={selectedJobId}
      />
    </Box>
  );
};
